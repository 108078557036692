<template>
  <div class="page_content page_content_saleevent">
    <div class="content_header d-flex align-items-center">
      <span>優惠活動</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between">
          <div class="search_section flex-fill">
            <div
              v-if="!edit_mode"
              class="form-group d-flex align-items-center"
            >
              <label for="saleevent_category">優惠分類</label>
              <select
                id="saleevent_category"
                v-model="search.category"
                name="優惠分類"
                class="form-control search_brand"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  分類
                </option>
                <option
                  v-for="item in saleevent_category"
                  :key="item.categoryCode"
                  :value="item.categoryCode"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
            <a
              v-if="!edit_mode"
              href="#"
              class="search_btn d-flex align-items-center justify-content-center rounded px-2"
              @click.prevent="init_saleevent(true)"
            >
              <span>建立優惠</span>
            </a>
            <a
              v-else
              href="#"
              class="search_btn d-flex align-items-center justify-content-center ml-auto rounded"
              @click.prevent="init_validate(), init_saleevent(false)"
            >
              <span>返回</span>
            </a>
            <template v-if="edit_mode">
              <a
                v-if="!edit_section_new"
                href="#"
                class="search_btn d-flex align-items-center justify-content-center ml-2 rounded"
                @click.prevent="edit_saleevent(edit_section_new)"
              >
                <span>更新</span>
              </a>
              <a
                v-if="edit_section_new"
                href="#"
                class="search_btn d-flex align-items-center justify-content-center ml-2 rounded"
                @click.prevent="edit_saleevent(edit_section_new)"
              >
                <span>建立</span>
              </a>
            </template>
            <div
              v-if="!edit_mode && search.systemCode !== '' && search.category !== ''"
              class="d-flex ml-auto"
            >
              <div class="form-group d-flex align-items-center m-0">
                <select
                  v-model="search.systemCode"
                  class="form-control"
                >
                  <option
                    :value="'ALL'"
                  >
                    不限
                  </option>
                  <option
                    v-for="item in system"
                    :key="item.systemCode"
                    :value="item.systemCode"
                  >
                    {{ item.systemName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!edit_mode && search.category !== ''"
          class="table-responsive"
        >
          <table class="saleevent_table table table-borderless">
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  優惠名稱
                </th>
                <th scope="col">
                  對應系統
                </th>
                <th scope="col">
                  有效日期
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(saleevent, index) in filter_saleevent"
                :key="index"
                :class="{ 'table_disabled': !saleevent.status }"
              >
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_saleevent_detail(saleevent.saleEventCode)"
                  >
                    {{ saleevent.saleEventName }}
                  </a>
                </td>
                <td>
                  <div>
                    {{ saleevent.systemCode }}
                  </div>
                </td>
                <td>
                  {{ saleevent.dtPublic }}~{{ saleevent.dtUnPublic }}
                </td>
                <td class="text-center">
                  <a
                    href="#"
                    class="text-danger"
                    @click.prevent="delete_salevent(saleevent.saleEventCode)"
                  >
                    <i class="icon-trash" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-show="edit_mode"
          class="edit_section"
        >
          <!-- <div class="section_header">
            <span v-show="edit_section_new">建立活動</span>
            <span v-show="!edit_section_new">編輯活動</span>
          </div> -->
          <div class="section_body">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'saleevent_info' }"
                  @click.prevent="switch_nav('saleevent_info')"
                >資訊</a>
              </li>
            </ul>
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_systemCode">對應系統</label>
                  <select
                    id="saleevent_systemCode"
                    v-model="saleevent_detail.systemCode"
                    v-validate="'required'"
                    name="對應系統"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.對應系統')}"
                    data-vv-scope="detail"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      對應系統
                    </option>
                    <option
                      v-for="item in system"
                      :key="item.systemCode"
                      :value="item.systemCode"
                    >
                      {{ item.systemName }}
                    </option>
                    <option
                      :value="'ALL'"
                    >
                      不限
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_categoryCode">分類</label>
                  <select
                    id="saleevent_categoryCode"
                    v-model="saleevent_detail.categoryCode"
                    v-validate="'required'"
                    name="分類"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.分類')}"
                    data-vv-scope="detail"
                    :disabled="edit_section_new ? false : true"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      分類
                    </option>
                    <option
                      v-for="item in saleevent_category"
                      :key="item.categoryCode"
                      :value="item.categoryCode"
                    >
                      {{ item.categoryName }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                v-if="!edit_section_new"
                class="col-12 col-xl-4"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_saleEventCode">活動代碼</label>
                  <input
                    id="saleevent_saleEventCode"
                    v-model="saleevent_detail.saleEventCode"
                    type="text"
                    name="活動代碼"
                    class="form-control form_input"
                    placeholder="活動代碼"
                    :disabled="edit_section_new ? false : true"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_saleEventName">活動名稱</label>
                  <input
                    id="saleevent_saleEventName"
                    v-model="saleevent_detail.saleEventName"
                    v-validate="'required'"
                    type="text"
                    name="活動名稱"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.活動名稱')}"
                    data-vv-scope="detail"
                    placeholder="活動名稱"
                  >
                </div>
              </div>
              <div
                v-if="!edit_section_new"
                class="col-12 col-xl-4"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_adminId">管理者</label>
                  <input
                    id="saleevent_adminId"
                    v-model="saleevent_detail.adminId"
                    v-validate="'required'"
                    type="text"
                    name="管理者"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.管理者')}"
                    data-vv-scope="detail"
                    placeholder="管理者"
                    :disabled="edit_section_new ? false : true"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_dtPublic">上架日期</label>
                  <input
                    id="saleevent_dtPublic"
                    v-model="saleevent_detail.dtPublic"
                    v-validate="'required'"
                    type="date"
                    name="上架日期"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.上架日期')}"
                    data-vv-scope="detail"
                    placeholder="YYYY-MM-DD"
                    :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                    :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_dtUnPublic">最後日期</label>
                  <input
                    id="saleevent_dtUnPublic"
                    v-model="saleevent_detail.dtUnPublic"
                    v-validate="'required'"
                    type="date"
                    name="最後日期"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.最後日期')}"
                    data-vv-scope="detail"
                    placeholder="YYYY-MM-DD"
                    :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                    :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                  >
                </div>
              </div>
              <div
                v-if="!edit_section_new"
                class="col-12 col-xl-4"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_dtCreate">建立日期</label>
                  <input
                    id="saleevent_dtCreate"
                    v-model="saleevent_detail.dtCreate"
                    type="date"
                    name="建立日期"
                    class="form-control form_input"
                    placeholder="YYYY-MM-DD"
                    :disabled="edit_section_new ? false : true"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="saleevent_status">狀態</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="saleevent_status_true"
                      v-model="saleevent_detail.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="狀態"
                      :value="true"
                      data-vv-scope="detail"
                    >
                    <label
                      class="form-check-label"
                      for="saleevent_status_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="saleevent_status_false"
                      v-model="saleevent_detail.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="狀態"
                      :value="false"
                      data-vv-scope="detail"
                    >
                    <label
                      class="form-check-label"
                      for="saleevent_status_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-baseline">
                  <label for="saleevent_saleEventDesc">活動敘述</label>
                  <div class="flex-fill">
                    <ckeditor
                      v-model="saleevent_detail.saleEventDesc"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="upload_article_image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="search_product_main_category">主分類</label>
                  <select
                    id="search_product_main_category"
                    v-model="search_product.main_category"
                    name="主分類"
                    class="form-control form_input"
                    @change="get_sub_category()"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      主分類
                    </option>
                    <option
                      v-for="item in main_category"
                      :key="item.categoryCode"
                      :value="item.categoryCode"
                    >
                      {{ item.categoryName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="search_product_sub_category">次分類</label>
                  <select
                    id="search_product_sub_category"
                    v-model="search_product.sub_category"
                    name="次分類"
                    class="form-control form_input"
                    @change="get_product_list()"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      次分類
                    </option>
                    <option
                      v-for="item in sub_category"
                      :key="item.categorySubCode"
                      :value="item.categorySubCode"
                    >
                      {{ item.categorySubName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="saleevent_detail.categoryCode !== ''"
              class="row"
            >
              <div
                class="col-12 col-xl-4"
                :class="{ 'col-xl-12': saleevent_detail.categoryCode === '05' }"
              >
                <p class="mb-2">
                  商品清單
                </p>
                <table class="product_table table table-borderless">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">
                        名稱
                      </th>
                      <th
                        scope="col"
                        class="text-center"
                      >
                        編輯
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in product_list"
                      :key="index"
                    >
                      <td>
                        <div class="mr-1">
                          <a
                            href="#"
                            class="text-color_primary"
                            @click.prevent=""
                          >
                            {{ product.prodSerial }}
                          </a>
                        </div>
                        <div>
                          {{ product.prodName }}/{{ product.standard }}
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="#"
                          @click.prevent="edit_saleevent_list('add', product, index)"
                        >
                          <i class="icon-add text-color_primary" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="col-12 col-xl-8"
                :class="{ 'col-xl-12': saleevent_detail.categoryCode === '05' }"
              >
                <template v-if="saleevent_detail.categoryCode === '03'">
                  <p class="mb-2">
                    條件清單
                  </p>
                  <table class="product_table table table-borderless">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">
                          條件商品
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          編輯
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in saleevent_detail.needProdSerial"
                        :key="index"
                      >
                        <td>
                          <div class="form-group d-flex align-items-center">
                            <input
                              v-model="saleevent_detail.needProdSerial[index]"
                              v-validate="'required'"
                              type="text"
                              :name="`商品代碼 ${index + 1}`"
                              class="form-control form_input"
                              :class="{'is-invalid': errors.has(`detail.商品代碼 ${index + 1}`)}"
                              data-vv-scope="detail"
                              placeholder="商品代碼"
                            >
                          </div>
                        </td>
                        <td class="text-center">
                          <a
                            href="#"
                            @click.prevent="edit_condition_list('remove', item, index)"
                          >
                            <i class="icon-minus text-danger" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="2"
                          class="text-center"
                        >
                          <a
                            href="#"
                            @click.prevent="edit_condition_list('add')"
                          >
                            <i class="icon-add text-color_primary" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template v-if="saleevent_detail.categoryCode === '04'">
                  <p class="mb-2">
                    條件設定
                  </p>
                  <table class="product_table table table-borderless">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">
                          條件數量
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="form-group d-flex align-items-center">
                            <input
                              v-model.number="saleevent_detail.minimumQunatity"
                              v-validate="'required'"
                              type="number"
                              name="條件數量"
                              class="form-control form_input"
                              :class="{'is-invalid': errors.has('detail.條件數量')}"
                              data-vv-scope="detail"
                              placeholder="條件數量"
                              min="1"
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <div>
                  <p class="mb-2">
                    活動設定
                  </p>
                  <table class="product_table table table-borderless">
                    <thead class="thead-light">
                      <tr>
                        <template v-if="saleevent_detail.categoryCode === '01'">
                          <th scope="col">
                            贈品代碼
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            購買門檻
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            數量
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            倍率
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            編輯
                          </th>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '02' || saleevent_detail.categoryCode === '04'">
                          <th scope="col">
                            編號
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            折數
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            編輯
                          </th>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '03'">
                          <th scope="col">
                            贈品代碼
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            贈品數量
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            條件數量
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            倍率
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            編輯
                          </th>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '05'">
                          <th scope="col">
                            商品代碼
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            折扣價
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            說明
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            最小數量
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            起
                          </th>
                          <th
                            scope="col"
                            class="text-right"
                          >
                            訖
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            運費
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                          >
                            編輯
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in saleevent_product_list"
                        :key="index"
                      >
                        <template v-if="saleevent_detail.categoryCode === '01'">
                          <td>
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model="item.giftProdSerial"
                                v-validate="'required'"
                                type="text"
                                :name="`贈品代碼 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.贈品代碼 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="贈品代碼"
                              >
                            </div>
                          </td>
                          <td class="text-right">
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model.number="item.minimumPrice"
                                v-validate="'required'"
                                type="number"
                                :name="`購買門檻 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.購買門檻 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="購買門檻"
                              >
                            </div>
                          </td>
                          <td class="text-right">
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model.number="item.giftProdQunatity"
                                v-validate="'required'"
                                type="number"
                                :name="`數量 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.數量 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="數量"
                              >
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <div class="form-group d-flex align-items-center justify-content-center">
                              <input
                                :id="`saleevent_optionMagnification_${index}_true`"
                                v-model="item.optionMagnification"
                                type="checkbox"
                                :name="`saleevent_optionMagnification_${index}`"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <a
                              href="#"
                              @click.prevent="edit_saleevent_list('remove', item, index)"
                            >
                              <i class="icon-minus text-danger" />
                            </a>
                          </td>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '02' || saleevent_detail.categoryCode === '04'">
                          <td>
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model="item.prodSerial"
                                v-validate="'required'"
                                type="text"
                                :name="`商品代碼 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.商品代碼 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="商品代碼"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model.number="item.discountRate"
                                v-validate="'required'"
                                type="number"
                                :name="`折扣 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.折扣 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="ex:9折請填0.9，88折請填0.88"
                                max="1"
                                min="0"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <a
                              href="#"
                              @click.prevent="edit_saleevent_list('remove', item, index)"
                            >
                              <i class="icon-minus text-danger" />
                            </a>
                          </td>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '03'">
                          <td>
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model="item.giftProdSerial"
                                v-validate="'required'"
                                type="text"
                                :name="`贈品代碼 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.贈品代碼 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="贈品代碼"
                              >
                            </div>
                          </td>
                          <td class="text-right">
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model.number="item.minimumQunatity"
                                v-validate="'required'"
                                type="number"
                                :name="`指定最小數量 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.指定最小數量 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="指定最小數量"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model.number="item.giftProdQunatity"
                                v-validate="'required'"
                                type="number"
                                :name="`贈品數量 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.贈品數量 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="贈品數量"
                              >
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <div class="form-group d-flex align-items-center justify-content-center">
                              <input
                                :id="`saleevent_optionMagnification_${index}_true`"
                                v-model="item.optionMagnification"
                                type="checkbox"
                                :name="`saleevent_optionMagnification_${index}`"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <a
                              href="#"
                              @click.prevent="edit_saleevent_list('remove', item, index)"
                            >
                              <i class="icon-minus text-danger" />
                            </a>
                          </td>
                        </template>
                        <template v-if="saleevent_detail.categoryCode === '05'">
                          <td>
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model="item.prodSerial"
                                v-validate="'required'"
                                type="text"
                                :name="`商品代碼 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.商品代碼 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="商品代碼"
                              >
                            </div>
                          </td>
                          <td>
                            <div class="form-group d-flex align-items-center justify-content-end">
                              <input
                                v-model.number="item.discountPrice"
                                v-validate="'required'"
                                type="number"
                                :name="`折扣價 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.折扣價 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="折扣價"
                              >
                            </div>
                          </td>
                          <td>
                            <div class="form-group d-flex align-items-center">
                              <input
                                v-model="item.priceArea"
                                v-validate="'required'"
                                type="text"
                                :name="`說明 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.說明 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="說明"
                              >
                            </div>
                          </td>
                          <td>
                            <div class="form-group d-flex align-items-center justify-content-end">
                              <input
                                v-model.number="item.minimumQunatity"
                                v-validate="'required'"
                                type="number"
                                :name="`最小數量 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.最小數量 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="最小數量"
                              >
                            </div>
                          </td>
                          <td>
                            <div class="form-group d-flex align-items-center justify-content-end">
                              <input
                                v-model="item.dtStart"
                                v-validate="'required'"
                                type="date"
                                :name="`起始日期 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.起始日期 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="YYYY-MM-DD"
                              >
                            </div>
                          </td>
                          <td>
                            <div class="form-group d-flex align-items-center justify-content-end">
                              <input
                                v-model="item.dtEnd"
                                v-validate="'required'"
                                type="date"
                                :name="`結束日期 ${index + 1}`"
                                class="form-control form_input text-right"
                                :class="{'is-invalid': errors.has(`detail.結束日期 ${index + 1}`)}"
                                data-vv-scope="detail"
                                placeholder="YYYY-MM-DD"
                              >
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <div class="form-group d-flex align-items-center justify-content-center">
                              <input
                                :id="`saleevent_optionTransFee_${index}`"
                                v-model="item.optionTransFee"
                                type="checkbox"
                                :name="`saleevent_optionTransFee_${index}`"
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <a
                              href="#"
                              @click.prevent="edit_saleevent_list('remove', item, index)"
                            >
                              <i class="icon-minus text-danger" />
                            </a>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_SALEEVENT_CATEGORY,
  API_GET_SALEEVENT_LIST,
  API_GET_SALEEVENT_DETAIL,
  API_GET_PRODUCT_CATEGORY,
  API_GET_PRODUCT_LIST,
  API_CREATE_SALEEVENT,
  API_UPDATE_SALEEVENT,
  API_DELETE_SALEEVENT,
} from '@/plugins/api';
import { ckeditor, MyUploadAdapter } from '@/plugins/ckeditor';

export default {
  data() {
    return {
      now_page: 'saleevent',
      search: {
        category: '',
        systemCode: 'ALL',
      },
      search_product: {
        main_category: '',
        sub_category: '',
      }, /* 搜尋商品列表 */
      main_category: [], /* 商品主分類資料 */
      sub_category: [], /* 商品次分類資料 */
      edit_mode: false, /* 編輯模式 */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      saleevent_category: [], /* 優惠活動分類 */
      saleevent_list: [], /* 取得優惠列表 */
      saleevent_detail: {
        systemCode: '',
        categoryCode: '',
        items: [],
        needProdSerial: [],
        minimumQunatity: 0,
        saleEventDesc: '',
      }, /* 優惠內容 */
      nav_tabs: 'saleevent_info',
      product_list: [], /* 商品列表 */
      editor: ckeditor.editor,
      editorConfig: ckeditor.editorConfig, /* 編輯器設定 */
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    filter_saleevent: {
      get() {
        const vm = this;
        /* 先給空陣列，避免 saleevent_list 還沒拿到資料出錯 */
        let filterCategory = [];
        filterCategory = vm.saleevent_list.slice();
        filterCategory = filterCategory.filter((item) => item.categoryCode === vm.search.category);
        return filterCategory.filter((item) => item.systemCode === vm.search.systemCode);
      },
      set() {},
    },
    saleevent_product_list: {
      get() {
        const vm = this;
        let data = vm.saleevent_detail.items || [];
        if (data.length === undefined) {
          data = [];
          data.push(vm.saleevent_detail.items);
        }
        return data;
      },
      set() {},
    },
  },
  watch: {
    'saleevent_detail.systemCode': {
      handler: async function systemCode(newValue, oldValue) {
        const vm = this;
        if (newValue !== '') {
          await vm.get_main_category();
        }
      },
      // deep: true,
      immediate: true,
    },
    'saleevent_detail.categoryCode': {
      handler: async function categoryCode(newValue, oldValue) {
        const vm = this;
        if (oldValue !== '') {
          // console.log('test2');
          vm.saleevent_detail.items = [];
          vm.saleevent_detail.minimumQunatity = 0;
          vm.saleevent_detail.needProdSerial = [];
        } else {
          // console.log('test1');
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.get_saleevent_category();
    await vm.get_saleevent_list();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 取得優惠分類 */
    async get_saleevent_category() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_SALEEVENT_CATEGORY();
      const res = response.data;
      if (res.code === '0000') {
        vm.saleevent_category = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得優惠列表 */
    async get_saleevent_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_SALEEVENT_LIST();
      const res = response.data;
      if (res.code === '0000') {
        vm.saleevent_list = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得優惠內容 */
    async get_saleevent_detail(saleEventCode) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_SALEEVENT_DETAIL(saleEventCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.saleevent_detail = res.data;
        vm.edit_mode = true;
        vm.edit_section_new = false;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 初始化 saleevent 內容 */
    async init_saleevent(isNew = false) {
      const vm = this;
      if (!isNew) {
        vm.saleevent_detail = {
          systemCode: '',
          categoryCode: '',
          items: [],
        };
        vm.edit_mode = isNew;
        vm.edit_section_new = isNew;
      } else {
        vm.edit_mode = isNew;
        vm.edit_section_new = isNew;
      }
      vm.search_product = {
        main_category: '',
        sub_category: '',
      };
      vm.main_category = [];
      vm.sub_category = [];
      vm.product_list = [];
      vm.editor = ckeditor.editor;
      vm.editorConfig = ckeditor.editorConfig;
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 取得主次分類列表 */
    async get_main_category() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      let { systemCode } = vm.saleevent_detail;
      if (vm.saleevent_detail.systemCode === 'ALL') {
        systemCode = 'GP';
      }
      const response = await API_GET_PRODUCT_CATEGORY(systemCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.main_category = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      vm.main_category.forEach((item) => {
        if (item.categoryCode === vm.search_product.main_category) {
          vm.sub_category = item.items;
        }
      });
    },
    /* 取得商品列表 */
    async get_product_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_PRODUCT_LIST(vm.search_product.sub_category);
      const res = response.data;
      if (res.code === '0000') {
        vm.product_list = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 建立/更新 優惠內容 */
    async edit_saleevent(isNew) {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        await vm.$store.dispatch('page_load', true);
        let data = {};
        let response;
        let res;
        switch (isNew) {
          case true:
            data = {
              status: vm.saleevent_detail.status,
              systemCode: vm.saleevent_detail.systemCode,
              saleEventName: vm.saleevent_detail.saleEventName,
              saleEventDesc: vm.saleevent_detail.saleEventDesc,
              dtPublic: vm.saleevent_detail.dtPublic,
              dtUnPublic: vm.saleevent_detail.dtUnPublic,
              items: vm.saleevent_detail.items,
              needProdSerial: vm.saleevent_detail.needProdSerial || null,
              minimumQunatity: vm.saleevent_detail.minimumQunatity || 0,
            };
            response = await API_CREATE_SALEEVENT(vm.saleevent_detail.categoryCode, data);
            res = response.data;
            if (res.code === '0000') {
              vm.$swal({
                icon: 'success',
                title: '成功',
                text: res.text,
                confirmButtonText: '關閉視窗',
              });
              vm.saleevent_list = res.data;
              await vm.init_validate();
              await vm.init_saleevent(false);
            }
            break;
          case false:
            data = {
              status: vm.saleevent_detail.status,
              systemCode: vm.saleevent_detail.systemCode,
              saleEventCode: vm.saleevent_detail.saleEventCode,
              saleEventName: vm.saleevent_detail.saleEventName,
              saleEventDesc: vm.saleevent_detail.saleEventDesc,
              dtPublic: vm.saleevent_detail.dtPublic,
              dtUnPublic: vm.saleevent_detail.dtUnPublic,
              items: vm.saleevent_detail.items,
              needProdSerial: vm.saleevent_detail.needProdSerial || null,
              minimumQunatity: vm.saleevent_detail.minimumQunatity || 0,
            };
            response = await API_UPDATE_SALEEVENT(vm.saleevent_detail.categoryCode, data);
            res = response.data;
            if (res.code === '0000') {
              vm.$swal({
                icon: 'success',
                title: '成功',
                text: res.text,
                confirmButtonText: '關閉視窗',
              });
              vm.saleevent_list = res.data;
              await vm.init_validate();
              await vm.init_saleevent(false);
            }
            break;
          default:
            break;
        }
        await vm.$store.dispatch('page_load', false);
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
        // vm.$swal({
        //   icon: 'error',
        //   title: '欄位錯誤!',
        //   text: '請確認欄位是否填妥',
        //   confirmButtonText: '關閉視窗',
        // });
      }
    },
    /* 處理優惠清單內容 */
    async edit_saleevent_list(target, content, index) {
      const vm = this;
      const { categoryCode } = vm.saleevent_detail;
      let item = {};
      switch (categoryCode) {
        case '01':
          item = {
            minimumPrice: 0,
            giftProdSerial: content.prodSerial,
            giftProdQunatity: 1,
            optionMagnification: false,
          };
          break;
        case '02':
          item = {
            prodSerial: content.prodSerial,
            discountRate: 1.0,
          };
          break;
        case '03':
          item = {
            minimumQunatity: 1,
            giftProdSerial: content.prodSerial,
            giftProdQunatity: 1,
            optionMagnification: false,
          };
          break;
        case '04':
          item = {
            prodSerial: content.prodSerial,
            discountRate: 1.0,
          };
          break;
        case '05':
          item = {
            dtStart: 'YYYY-MM-DD',
            dtEnd: 'YYYY-MM-DD',
            priceArea: '時段說明',
            prodSerial: content.prodSerial,
            minimumQunatity: 1,
            discountPrice: 0,
            optionTransFee: false,
          };
          break;
        default:
          break;
      }
      switch (target) {
        case 'add':
          if (categoryCode === '03') {
            vm.saleevent_detail.items = item;
          } else {
            vm.saleevent_detail.items.push(item);
          }
          break;
        case 'remove':
          if (categoryCode === '03') {
            vm.saleevent_detail.items = [];
          } else {
            vm.saleevent_detail.items.splice(index, 1);
          }
          break;
        default:
          break;
      }
    },
    /* 處理條件清單 */
    async edit_condition_list(target, content = {}, index = 0) {
      const vm = this;
      const { categoryCode } = vm.saleevent_detail;
      let item = null;
      switch (categoryCode) {
        case '03':
          item = '';
          break;
        case '04':
          item = {
            prodSerial: content.prodSerial,
            discountRate: 1.0,
          };
          break;
        default:
          break;
      }
      // console.log('item', item);
      switch (target) {
        case 'add':
          // console.log('add', item);
          vm.saleevent_detail.needProdSerial.push(item);
          break;
        case 'remove':
          // console.log('remove');
          vm.saleevent_detail.needProdSerial.splice(index, 1);
          break;
        default:
          break;
      }
    },
    /* 刪除優惠 */
    async delete_salevent(saleEventCode) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_DELETE_SALEEVENT(saleEventCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.saleevent_list = res.data;
        await vm.init_validate();
        await vm.init_saleevent(false);
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* ckeditor 上傳圖片 */
    upload_article_image(editor) {
      const vm = this;
      // 自定義上傳圖片插件
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new MyUploadAdapter(vm.saleevent_detail.saleEventCode, loader);
    },
  },
};
</script>
